require('./vjs-api-hooks/vjs-api-hooks-plugin');
require('./duration/duration-plugin');
require('./ads-setup/ads-setup-plugin');
require('./cmp/cmp-plugin');
require('./social-share/social-share-plugin');
require('./sources/sources-plugin');
require('./streaming/streaming-plugin');
require('./title/title-plugin');
require('./history/history-plugin');
require('./autoplay-next-video/autoplay-next-video-plugin');
require('./flyout/flyout-plugin');
require('./playlist/playlist-plugin');
require('./related-videos/related-videos-plugin');
require('./reset/reset-plugin');
require('./tracking/tracking-plugin');
require('./video-starts/video-starts-plugin');
require('./animated-preview/index');
require('./editorial-content/editorial-content-plugin');
require('./autoplay-inline/autoplay-inline-plugin');
require('./video-advertising-mode/video-advertising-mode-plugin');
require('./commercial-banner/commercial-banner-plugin');
require('./portrait-mode/portrait-mode-plugin');
require('./key-moments/key-moments-plugin');
require('./headless-mode/headless-mode-plugin');
require('./detect-interrupted-play/detect-interrupted-play-plugin');
